import React, { FunctionComponent, useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import classNames from 'classnames';

const ProjectsPage: FunctionComponent = () => {
  const images = useStaticQuery(graphql`
    query {
      tasCourierImages: allFile(filter: { relativePath: { glob: "tas-courier/*.*" } }) {
        nodes {
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      parentiaImages: allFile(filter: { relativePath: { glob: "parentia-drupal/*.*" } }) {
        nodes {
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      aosImages: allFile(filter: { relativePath: { glob: "aos/*.*" } }) {
        nodes {
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);
  const tasCourierImages = images.tasCourierImages.nodes;
  const [tasCourierImageIndex, setTasCourierImageIndex] = useState(0);

  const parentiaImages = images.parentiaImages.nodes;
  const [parentiaImageIndex, setParentiaImageIndex] = useState(0);

  const aosImages = images.aosImages.nodes;
  const [aosImageIndex, setAosImageIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (tasCourierImageIndex === tasCourierImages.length - 1) {
        setTasCourierImageIndex(0);
      } else {
        setTasCourierImageIndex(tasCourierImageIndex + 1);
      }
      if (parentiaImageIndex === parentiaImages.length - 1) {
        setParentiaImageIndex(0);
      } else {
        setParentiaImageIndex(parentiaImageIndex + 1);
      }
      if (aosImageIndex === aosImages.length - 1) {
        setAosImageIndex(0);
      } else {
        setAosImageIndex(aosImageIndex + 1);
      }
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [tasCourierImageIndex, parentiaImageIndex, aosImageIndex]);

  return (
    <>
      <h1>
        Some <strong>projects</strong> I have worked on
      </h1>
      <article className="c-project">
        <div className="c-project-image__container">
          {tasCourierImages.map((image: any, index: number) => {
            const classes = classNames({
              'c-project-image': true,
              'c-project-image--active': index === tasCourierImageIndex,
            });

            return (
              <GatsbyImage
                key={image.relativePath}
                className={classes}
                fluid={image.childImageSharp.fluid}
                alt="TAS Courier webapp screenshots"
              />
            );
          })}
        </div>
        <h2>Courier Webapp for T.A.S. bvba</h2>
        <h3>2020</h3>
        <p>
          T.A.S. needed a web application in which couriers could easily log all sorts of information. They also required a management
          console for inserting vehicles, adding users, filter the data, ...
        </p>
        <p>
          I made an application in <strong>React</strong> coupled with <strong>Firebase</strong>. All data is available in the management
          console in <strong>real-time</strong>. The couriers use a <strong>highly optimised and mobile friendly form</strong> for inserting
          the required data.
        </p>
      </article>

      <article className="c-project">
        <div className="c-project-image__container">
          {parentiaImages.map((image: any, index: number) => {
            const classes = classNames({
              'c-project-image': true,
              'c-project-image--active': index === parentiaImageIndex,
            });

            return (
              <GatsbyImage
                key={image.relativePath}
                className={classes}
                fluid={image.childImageSharp.fluid}
                alt="Parentia website screenshots"
              />
            );
          })}
        </div>
        <h2>Parentia website</h2>
        <h3>
          <a className="hoverable" href="https://parentia.be" target="_blank" rel="noreferrer">
            parentia.be
          </a>{' '}
          - 2020/2021
        </h3>
        <p>
          Parentia needed to translate multiple highly complex designs into code. These designs needed to fit into their existing{' '}
          <strong>drupal</strong> website.
        </p>
        <p>
          After partially refactoring their existing <strong>postCSS</strong>, I translated the designs into{' '}
          <strong>maintainable code</strong> following the <strong>BEM</strong> methodology. All while pushing for maximum{' '}
          <strong>browser support</strong> and <strong>responsiveness</strong>.
        </p>
      </article>

      <article className="c-project">
        <div className="c-project-image__container">
          {aosImages.map((image: any, index: number) => {
            const classes = classNames({
              'c-project-image': true,
              'c-project-image--active': index === aosImageIndex,
            });

            return (
              <GatsbyImage
                key={image.relativePath}
                className={classes}
                fluid={image.childImageSharp.fluid}
                alt="Art of Sport website screenshots"
              />
            );
          })}
        </div>
        <h2>Company website for Art of Sports</h2>
        <h3>
          <a className="hoverable" href="https://artofsports.be">
            artofsports.be
          </a>{' '}
          - 2020
        </h3>
        <p>Art of Sports was opening its doors and they needed a strong presence on the web.</p>
        <p>
          I build them I highly performant website using <strong>gridsome</strong> (a <strong>Vue.js</strong> framework) coupled with a{' '}
          <strong>headless CMS</strong> built with <strong>directus</strong> so they would be able to update all content on their own.
        </p>
      </article>
      <article className="c-project">
        <h2>There is more...</h2>
        <p>Not everything is for public eyes, though. To find out more do not hesitate to contact me.</p>

        <div className="u-mar-top-2">
          <Link to="/contact/" className="u-button">
            Get in touch..
          </Link>
        </div>
      </article>
    </>
  );
};

export default ProjectsPage;
